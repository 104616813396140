import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import MissionAndVissionSection from '../Section/MissionAndVisionSection';
import ServiceSection from '../Section/ServiceSection';
import TeamSection from '../Section/TeamSection';
import TestimonialSection from '../Section/TestimonialSection';
import { pageTitle } from '../../helpers/PageTitle';
import config from '../../config';
import { useInformation } from '../../apiCalling/useInformatio';

export default function MissionAndVission() {
  pageTitle('Mission & Vision');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	const { data } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (data?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + data?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Mission & Vision" bgUrl={BreadcrumbImage} />

      <Section
        pt="50"
        ptLg="50"
        pb="50"
        pbLg="50"
        className="position-relative"
      >
        <MissionAndVissionSection
          thumbUrl="/images/experience_img.jpeg"
          experienceBgUrl="/images/experience_bg.jpeg"
          experienceIconUrl="/images/experience_icon.svg"
          experienceNumber="40"
          experienceTitle="Work Experience"
          sectionTitleUp="Mission & Vision"
          sectionTitle='Get Ready for Success with <span className="text-accent">Partex Group</span> Your Mission and Vision.'
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses. Creating visual content, such as logos, brochures, infographics, and other branding materials."
          grothTitle="Plastic Items"
          grothPercentage="90"
          featureList={[
            {
              title: 'Accounting and Bookkeeping',
              subTitle:
                'Services related to financial record-keeping, bookkeeping.',
            },
            {
              title: 'Plastics and Cloth',
              subTitle: 'Plastic items  and cloth items.',
            },
          ]}
          btnText="Get A Quote"
          btnUrl="/contact"
          videoBtnText="Watch the video"
          videoBtnUrl="https://www.youtube.com/embed/0Tz4Ycjbdbg"
        />
      </Section>

      {/* <Section pt="140" ptLg="80" pb="130" pbLg="70" className="bg-primary">
        <ServiceSection
          data={serviceData}
          sectionTitleDown="<span className='text-accent'>Digital agency</span> services built specifically for your business"
          sectionBtnText="Find More Services"
          sectionBtnUrl="/service"
          textVariant="text-white"
        />
      </Section>

      <Section pt="133" ptLg="75">
        <TeamSection
          data={teamData}
          sectionTitle="Meet the professional team <br/>behind the success"
          sectionTitleUp="Meet Our Team Member"
        />
      </Section>

      <Section
        pt="110"
        ptLg="55"
        pb="140"
        pbLg="80"
        style={{ backgroundImage: `url(${'/images/testimonial_bg.jpeg'})` }}
      >
        <TestimonialSection
          sectionTitleUp="Testimonial"
          sectionTitle="What They’re Saying?"
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses."
          data={testimonialData}
        />
      </Section> */}
    </>
  );
}
