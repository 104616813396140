import React, { useEffect } from "react";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle.js";
import Breadcrumb from "../Breadcrumb/index.jsx";
import { useExport } from "../../apiCalling/useExport.js";
import SectionHeadingStyle2 from "../SectionHeading/SectionHeadingStyle2.jsx";
import config from "../../config/index.js";
import { useInformation } from "../../apiCalling/useInformatio.js";

export default function ExportPage() {
	pageTitle("Export");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data } = useExport();
	console.log("useExport--", data);

	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Export" bgUrl={BreadcrumbImage} />
      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <div className="container">
          {/* <SectionHeadingStyle2 sectionTitleUp="Our Export" sectionTitle="Find Out Export" /> */}
          <div className="row">
            {data?.map((item, index) => (
              <React.Fragment key={index}>
                <h2>{item.name}</h2>
                <p className="text-justify">{item.description}</p>
                {item?.exportDetailsVM?.map((detail, detailIndex) => (
                  <div className="row cs_mb_30 pt-10" key={detailIndex}>
                    <div className="col-xl-4">
                      <img
                        src={`${config.baseUrl}${detail.imgLink}`}
                        alt=""
                        className="cs_mb_30"
                      />
                    </div>
                    <div className="col-xl-8">
                      <h3 className="cs_fs_26 cs_mb_15">{detail.name}</h3>
                      <p className="cs_mb_40">{detail.description}</p>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
}
