import { useEffect } from 'react';
import Section from '../Section/index.jsx';
import { pageTitle } from '../../helpers/PageTitle.js';
import Breadcrumb from '../Breadcrumb/index.jsx';
import { useTradeFair } from '../../apiCalling/useTradeFair.js';
import ServiceSectionStyle5 from '../Section/ServiceSection/ServiceSectionStyle5';
import config from '../../config/index.js';
import { useInformation } from '../../apiCalling/useInformatio.js';

export default function TradeFairPage() {
  pageTitle('Trade Fair');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {data} = useTradeFair()
  console.log("useConcernMenu--", data)
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Trade Fair" bgUrl={BreadcrumbImage} />
      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <ServiceSectionStyle5
          sectionTitleUp="Trade Fair"
          sectionTitle="Here is our trade fair activities <br />For Our Clients"
          data={data}
        />
      </Section>
    </>
  );
}
