export const BasicInformation = {
companyName: 'Partex Star Group',
address: 'chittangong khulshi',
email: 'info@gmail.com',
phone: "+88012624325"

}

export const FooterInfo = {
    year: '2024',
    email: 'partex@gmail.com',
    phone: '+88012624325'
}

export const ContactInformation = {
    address: 'chittangong khulshi',
    email: 'partex@gmail.com',
    phone: "+88012624325"
}

export const SocialMediaLink = {
    facebook: 'www.facebook.com',
    linkdin: 'www.linkdin.com',
    twiter: 'www.twiter.com'
} 