import { useInformation } from "../../../apiCalling/useInformatio";

export default function MissionAndVissionSection({
	thumbUrl,
	experienceBgUrl,
	experienceIconUrl,
	experienceNumber,
	experienceTitle,
	sectionTitleUp,
	sectionTitle,
	sectionSubTitle,
	grothTitle,
	grothPercentage,
	featureList,
	btnText,
	btnUrl,
	videoBtnText,
	videoBtnUrl,
}) {
	const { data } = useInformation();
	return (
		<>
			<div className="container">
				{/* <div className="row">
          <div className="col-lg-6">
            <div className="cs_about cs_style_1">
              <SectionHeadingStyle3
                sectionTitleUp={sectionTitleUp}
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                className={'cs_mb_15'}
              />
              <div className="cs_progressbar cs_style_1 cs_mb_20">
              </div>
            </div>
          </div>
        </div> */}
				<div className={`cs_section_heading cs_style_1 align-items-center cs_mb_15`}>
					<div className="cs_section_heading_in">
						<h2
							className="cs_fs_30 cs_fs_lg_18 text-accent fw-normal cs_lh_base cs_mb_10 wow fadeInLeft"
							data-wow-duration="0.8s"
							data-wow-delay="0.2s"
						>
							Our Mission
						</h2>
						<p className="text-justify">{data?.companyMission}</p>
					</div>
					<div className="cs_section_heading_in">
						<h2
							className="cs_fs_30 cs_fs_lg_18 text-accent fw-normal cs_lh_base cs_mb_10 wow fadeInLeft"
							data-wow-duration="0.8s"
							data-wow-delay="0.2s"
						>
							Our Vission
						</h2>
						<p className="text-justify">{data?.companyVision}</p>
					</div>
				</div>
			</div>
		</>
	);
}
