import React from "react";
import { Link } from "react-router-dom";
import SocialBtns from "../../components/SocialBtns";
import MenuWidget from "../Widget/MenuWidget";
import NewsLetterWidget from "../Widget/NewsLetterWidget";
import { BasicInformation, FooterInfo } from "../../constatnts/information";
import { useInformation } from "../../apiCalling/useInformatio";
const menuDataOne = [
	{ title: "About", href: "/about" },
	{ title: "Concern", href: "/concern" },
	{ title: "Export", href: "/export" },
	{ title: "News", href: "/shop" },
	{ title: "Gallery", href: "/gallery" },
	{ title: "Privacy Policy", href: "/" },
	{ title: "Contact", href: "/contact" },
	{ title: "Faq", href: "/faq" },
];
export default function Footer() {
	const { data } = useInformation();
	return (
		<footer
			className="cs_footer background-filled text-white"
			// style={{ backgroundImage: 'url("/images/footer_bg.jpeg")' }}
			style={{
				background: "black",
			}}
		>
			<div className="container">
				<div className="cs_footer_top d-flex justify-content-between align-items-start align-items-md-center cs_pt_46 cs_pb_46 cs_column_gap_15 cs_row_gap_15 flex-md-row  flex-column">
					<div className="cs_footer_contact_info">
						<h3 className="text-accent fw-normal cs_mb_4 cs_fs_16 cs_lh_lg">Have Any Question?</h3>
						<h2 className="text-white fw-medium m-0 cs_fs_22">
							<a href={`tel:${data?.companyPhone}`}>{data?.companyPhone}</a>
						</h2>
					</div>
					<div className="cs_footer_logo">
						{/* <img src="/images/logo.svg" alt="Logo" /> */}
						<img src="/images/logo.png" className="brand-logo" alt="Logo" />
					</div>
					<div className="cs_footer_contact_info">
						<h3 className="text-accent fw-normal cs_mb_4 cs_fs_16 cs_lh_lg">Send Email</h3>
						<h2 className="text-white fw-medium m-0 cs_fs_22">
							<a href={`mailto:${data?.companyEmail}`}>{data?.companyEmail}</a>
						</h2>
					</div>
				</div>
				<div className="cs_footer_main cs_pt_30 cs_pb_30">
					<div className="row">
						<div className="col-lg-4">
							<div className="cs_footer_item cs_pt_20 cs_pb_20">
								<div className="cs_text_widget">
									<p>
										{data?.footerInfo}
									</p>
								</div>
								<SocialBtns
									variant="cs_social_btns d-flex flex-wrap cs_column_gap_15 cs_row_gap_15 cs_transition_5"
									data={data}
								/>
							</div>
						</div>
						<div className="col-lg-4 offset-lg-1">
							<MenuWidget data={menuDataOne} title="Explore" />
						</div>
						<div className="col-lg-3">
							<div className="cs_footer_item cs_pt_20 cs_pb_20">
								<NewsLetterWidget
									title="Newsletter"
									subTitle={data?.newsletterInfo}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="cs_copyright text-center cs_fs_124 cs_lh_lg cs_pt_36 cs_pb_36">
				<div className="container">
					<p className="m-0">
						Copyright © {new Date().getFullYear()}{" "}
						<Link to="/">{BasicInformation.companyName}</Link>. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
	);
}
