import React from 'react';
import SectionHeadingStyle2 from '../../SectionHeading/SectionHeadingStyle2';
import config from '../../../config';
import TradeFairDetails from '../../TradeFairDetails';

export default function TradeFairDetailsSection({ data, sectionTitle, sectionTitleUp }) {
  return (
    <div className="container">
      {sectionTitle && (
        <SectionHeadingStyle2
          sectionTitle={sectionTitle}
          sectionTitleUp={sectionTitleUp}
        />
      )}

      <div className="row">
        {data?.tradeFairImagesVM?.map((item, index) => (
          <div className="col-lg-4" key={index}>
            <TradeFairDetails
              key={index}
              imgLink={config.baseUrl+ item.imgLink}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
