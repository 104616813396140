import axios from 'axios';
import { from } from 'rxjs';
import config from '../../src/config/index'

// Axios instance configuration
const api = axios.create({
  baseURL: config.baseUrl,
  timeout: 10000,
});
console.log("config--", config)

// Helper function to create an Observable from an Axios request
const axiosRequest = (config)=> {
  return from(api.request(config).then(response => response.data));
};

export const get = (url)=> {
  return axiosRequest({ method: 'GET', url });
};

export const post = (url, data)=> {
  return axiosRequest({ method: 'POST', url, data });
};

export const put = (url, data)=> {
  return axiosRequest({ method: 'PUT', url, data });
};

export const del = (url)=> {
  return axiosRequest({ method: 'DELETE', url });
};
