import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import AboutPage from "./components/Pages/AboutPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import OurStorePage from "./components/Pages/shop/OurStorePage";
import ContactPage from "./components/Pages/ContactPage";
import MissionAndVission from "./components/Pages/MissionAndVission";
import ConcernDetailsPage from "./components/Pages/ConcernDetailsPage";
import ProductListPage from "./components/Pages/shop/ProductList";
import FaqPage from "./components/Pages/Faq";
import NewsPage from "./components/Pages/NewsPage";
import NewsDetailsPage from "./components/Pages/NewsDetailsPage";
import GalleryPage from "./components/Pages/GalleryPage";
import GalleryDetailsPage from "./components/Pages/GalleryDetailsPage";
import CorporateValuesPage from "./components/Pages/CorporateValuesPage";
import CrestPage from "./components/Pages/CrestPage";
import CertificatesPage from "./components/Pages/CertificatesPage";
import TradeFairPage from "./components/Pages/TradeFairPage";
import ExportPage from "./components/Pages/ExportPage";
import ConcernPage from "./components/Pages/ConcernPage";
import TradeFairDetailsPage from "./components/Pages/TradeFairDetailsPage";
import ProductDetailsPage from "./components/Pages/shop/ProductDetailsPage";
import CareerPage from "./components/Pages/CareerPage";
import CareerDetailsPage from "./components/Pages/CareerDetailsPage";
import { CompanyInformationApiProvider } from "./components/CompanyInformation/CompanyInformation";
import KeyAdminPage from "./components/Pages/KeyAdminPage";
import VideosPage from "./components/Pages/VideosPage";

function App() {
	return (
		<CompanyInformationApiProvider>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="/concern" element={<ConcernPage />} />
					<Route path="/concern/:url" element={<ConcernDetailsPage />} />
					{/* <Route path="/finance" element={<Home3 />} />
				<Route path="/business-with-ecommerce" element={<Home6 />} /> */}
					<Route path="/about" element={<AboutPage />} />
					<Route path="/keyadmin" element={<KeyAdminPage />} />
					<Route path="/mission" element={<MissionAndVission />} />
					<Route path="/portfolio" element={<PortfolioPage />} />
					<Route path="/portfolio/:portfolioId" element={<PortfolioDetailsPage />} />
					<Route path="/gallery" element={<GalleryPage />} />
					<Route path="/gallery/:galleryId" element={<GalleryDetailsPage />} />
					{/* <Route path="/team" element={<TeamPage />} />
				<Route path="/team/:teamId" element={<TeamMemberPage />} /> */}
					{/* <Route path="/pricing" element={<PricingPage />} /> */}
					<Route path="/product" element={<ProductListPage />} />
					{/* <Route path="/shop/:productId" element={<ProductDetailsPage />} /> */}
					<Route path="/product/:catagoryID" element={<OurStorePage />} />
					<Route path="/product-details/:productId" element={<ProductDetailsPage />} />
					{/* <Route path="/shop-cart" element={<ShopCartPage />} />
				<Route path="/shop-checkout" element={<ShopCheckoutPage />} />
				<Route path="/shop-order-recived" element={<ShopOrderRecived />} />
				<Route path="/blog-grid" element={<BlogGridPage />} />
				<Route path="/blog" element={<BlogListPage />} />
				<Route path="/blog/:blogId" element={<BlogDetailsPage />} /> */}
					<Route path="/contact" element={<ContactPage />} />
					<Route path="/faq" element={<FaqPage />} />
					<Route path="/corporate-values" element={<CorporateValuesPage />} />
					<Route path="/export" element={<ExportPage />} />
					<Route path="/trade-fair" element={<TradeFairPage />} />
					<Route path="/trade-fair/:tradeFairId" element={<TradeFairDetailsPage />} />
					<Route path="/certificates" element={<CertificatesPage />} />
					<Route path="/crest" element={<CrestPage />} />
					<Route path="/videos" element={<VideosPage />} />
					<Route path="/news" element={<NewsPage />} />
					<Route path="/news/:newsId" element={<NewsDetailsPage />} />
					<Route path="/career" element={<CareerPage />} />
					<Route path="/career/:careerId" element={<CareerDetailsPage />} />
				</Route>
				{/* <Route path="/insurance" element={<LayoutStyle2 />}>
				<Route index element={<Home4 />} />
			</Route>
			<Route path="/consulting" element={<LayoutStyle3 />}>
				<Route index element={<Home5 />} />
			</Route> */}
			</Routes>
		</CompanyInformationApiProvider>
	);
}

export default App;
