import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import LocationInMap from "../LocationInMap";
import Section from "../Section";
import FormStyle2 from "../Form/FormStyle2";
import IconboxStyle2 from "../Iconbox/IconboxStyle2";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { useInformation } from "../../apiCalling/useInformatio";
import IconboxStyle1 from "../Iconbox/IconboxStyle1";
import { useContactInformation } from "../../apiCalling/contactInformation";
import { Icon } from "@iconify/react/dist/iconify.js";
import config from "../../config";
const locationData =
	"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.359030864805!2d90.4038373760234!3d23.770226088013594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c77bbcff9331%3A0x5ed9dcf8785ca319!2sPartex%20Star%20Group!5e0!3m2!1sen!2sbd!4v1719076948904!5m2!1sen!2sbd";
export default function ContactPage() {
	const { data } = useInformation();
	const { data: contactInformation } = useContactInformation();
	console.log("information--->", data);
	pageTitle("Contact");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (data?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + data?.pageBannerLink;
	return (
		<>
			<Breadcrumb title="Contact" bgUrl={BreadcrumbImage} />
			<Section pt="50" ptLg="50" pb="50" pbLg="50">
				<div className="container">
					<div className="row">
						<div className="col-xl-7 col-lg-6">
							<SectionHeadingStyle3
								sectionTitleUp="Have any question?"
								sectionTitle="Write a Message"
							/>
							<FormStyle2 />
						</div>
						<div className="col-xl-5 col-lg-6">
							<div className="cs_pl_60 cs_pl_lg_0 cs_pt_lg_60">
								<SectionHeadingStyle3
									sectionTitleUp="Contact With Us"
									sectionTitle="Let’s work together"
									sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses."
									className="cs_mb_40"
								/>
								<div className="cs_iconbox_wrapper cs_style1">
									<IconboxStyle2
										imgUrl="/images/icons/contact_icon_1.svg"
										title={data?.companyPhone}
										titleUp="Have Any Question?"
									/>
									<IconboxStyle2
										imgUrl="/images/icons/contact_icon_2.svg"
										title={data?.companyEmail}
										titleUp="Send Email"
									/>
									<IconboxStyle1
										imgUrl="/images/icons/contact_icon_3.svg"
										title={data?.companyAddress}
										titleUp="Address"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="cs_mt_40">
						<div className="row justify-content-md-center">
							{contactInformation?.map((item, index) => (
								<div className="col-md-6">
									<div className="box-shadow common_icon_color cs_p_20">
										<h4 className="text-accent">{item.title}</h4>
										<Icon icon="fa:user" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.name}<br />
										<Icon icon="carbon:information-filled" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.designation}<br />
										<Icon icon="fa:phone" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <a href={'tel:'+item.phone}>{item.phone}</a> <br />
										<Icon icon="ic:baseline-email" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href={'mailto:'+item.email}>{item.email}</a> 
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</Section>
			<LocationInMap data={locationData} />
		</>
	);
}
