import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helpers/PageTitle.js";
import Breadcrumb from "../Breadcrumb/index.jsx";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config/index.js";
import Section from "../Section/index.jsx";
import ConcernContentCol1 from "../ConcernContent/ConcernContentCol1.jsx";
import ConcernContentCol2 from "../ConcernContent/ConcernContentCol2.jsx";
import ConcernContentCol3 from "../ConcernContent/ConcernContentCol3.jsx";
import { useInformation } from "../../apiCalling/useInformatio.js";

const locationData =
	"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd";

export default function ConcernDetailsPage() {
	const { url } = useParams();
	const [data, setData] = useState([]);
	pageTitle("Corporate Home");
	useEffect(() => {
		axios.get(`${config.baseUrl}api/WebApi/GetConcernByUrl?url=` + url).then((response) => {
			setData(response.data);
		});
	}, [url]);
	console.log("Concern Details--", data);
	const { data: information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	console.log("useConcernMenu--", data);
	const backgroundImageStyle = {
		backgroundImage: `url(${config.baseUrl + data?.bgImg})`,
		backgroundSize: "cover", // Ensures the background image covers the entire div
		backgroundRepeat: "no-repeat", // Prevents the background image from repeating
		backgroundPosition: "center", // Centers the background image
		weidth: "100%",
		height: "300px",
	};
  const renderContent = () => {
    switch (data?.contentColumn) {
        case 1:
            return <ConcernContentCol1 data={data?.concernContent} />;
        case 2:
            return <ConcernContentCol2 data={data?.concernContent} />;
        default:
            return <ConcernContentCol3 data={data?.concernContent} />;
    }
};
	return (
		<>
			<Breadcrumb
				title="Concern details"
				lastTitle={data?.name}
				bgUrl={BreadcrumbImage}
			/>
			<Section pt="100" ptLg="80" pb="100" pbLg="80">
				<div className="container">
					<h1 className="text-accent text-center">{data?.name}</h1>
					<div style={backgroundImageStyle}></div>
					<p>{data?.title}</p>
					<h3 className="text-accent text-center">Mission</h3>
					<div className="row">
						<div className="col-md-4">
							<img src={`${config.baseUrl}${data?.missionImg}`} alt="Product" />
						</div>
						<div className="col-md-8">
							<p className="text-justify">{data?.mission}</p>
						</div>
					</div>
					<h3 className="text-accent text-center">Vission</h3>
					<div className="row">
						<div className="col-md-8">
							<p className="text-justify">{data?.vission}</p>
						</div>
						<div className="col-md-4">
							<img src={`${config.baseUrl}${data?.vissionImg}`} alt="Product" />
						</div>
					</div>
				</div>
			</Section>
			{renderContent()}
		</>
	);
}
