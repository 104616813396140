import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function GalleryVideosStyle3({ imgLink, isVideo }) {
  return (
    <>
      <div className="cs_team text-center cs_mb_25 overflow-hidden">
        <div className="cs_team_member position-relative">
          <iframe width="400" height="250" src={imgLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe >
        </div>
      </div>
    </>
  );
}
