import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import SectionHeadingStyle2 from '../SectionHeading/SectionHeadingStyle2';
import Accordion from '../Accordion';
import IconboxStyle5 from '../Iconbox/IconboxStyle5';
import { NavLink } from 'react-router-dom';
import FormStyle5 from '../Form/FormStyle5';
import { Icon } from '@iconify/react';
import FormStyle6 from '../Form/FormStyle6';
import Button from '../Button';
import PostSection from '../Section/PostSection';
import { pageTitle } from '../../helpers/PageTitle';
import { useGetNews } from '../../apiCalling/useGetNews';
import { useInformation } from '../../apiCalling/useInformatio';
import config from '../../config';



export default function NewsPage() {
  const {data} = useGetNews()
  pageTitle('News');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="News" bgUrl={BreadcrumbImage} />

      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <PostSection
          data={data}
          sectionTitleUp="Find The Blogs"
          sectionTitle="Find Out Latest News <br/> and Articles"
        />
      </Section>
    </>
  );
}
