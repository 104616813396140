import React, { useEffect } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import AboutUsSection from "../Section/AboutSection";
import ServiceSection from "../Section/ServiceSection";
import TeamSection from "../Section/TeamSection";
import TestimonialSection from "../Section/TestimonialSection";
import { pageTitle } from "../../helpers/PageTitle";
import { useInformation } from "../../apiCalling/useInformatio";
import config from "../../config";
import { BasicInformation } from "../../constatnts/information";
import { useSpeech } from "../../apiCalling/useSpeech";

export default function KeyAdminPage() {
	pageTitle("Key Admin/Executives");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { data } = useInformation();
	const { data: speech } = useSpeech();
	const groupedItems = speech?.reduce((acc, item) => {
		const group = item.type == "Director" ? 'Director' : 'Admin';
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (data?.pageBannerLink)
		BreadcrumbImage = config.baseUrl+data?.pageBannerLink;
	console.log("useConcernMenu--", data);
	return (
		<>
			<Breadcrumb title="Key Admin/Executives" bgUrl={BreadcrumbImage} />
			<Section pt="50" ptLg="50" pb="50" pbLg="50" className="position-relative">
				<div className="container">
					<div className="row justify-content-md-center">
						{groupedItems && groupedItems.Admin && (
							groupedItems.Admin?.map((item, index) => (
								<div className="col-md-6 col-sm-12">
									<div className="cs_testimonial_img text-center cs_mb_15">
										<img
											src={config.baseUrl + item.imgLink}
											alt={item.name}
											className="cs_height_75 cs_width_75 rounded-circle"
										></img>
									</div>
									<h3 className="text-center cs_fs_18 cs_mb_2 cs_lh_base">{item.name}</h3>
									<p className="text-center m-0 cs_fs_14 cs_lh_base">{item.designation}</p>
									<div className="pt-10">
										<p className="text-justify">{item.description}</p>
									</div>
								</div>
							))
						)}
					</div>
				</div>
			</Section>
		</>
	);
}
