import { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import BrandSection from '../Section/BrandSection';
import ServiceSectionStyle4 from '../Section/ServiceSection/ServiceSectionStyle4';
import TestimonialSection from '../Section/TestimonialSection';
import { pageTitle } from '../../helpers/PageTitle';
import { useGallery } from '../../apiCalling/useGallery';
import config from '../../config';
import { useInformation } from '../../apiCalling/useInformatio';

export default function GalleryPage() {
  const {data} = useGallery()
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  pageTitle('Photo Gellery');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="Our Photo Gellery" bgUrl={BreadcrumbImage} />

      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <ServiceSectionStyle4
          sectionTitleUp="Our Photo Gellery"
          sectionTitle="Here is our activities and programs <br />For Our Clients"
          data={data}
        />
      </Section>
    </>
  );
}
