import {useGetData} from '../../src/apiservice/apiCall'


export function useGetNews(){
	return useGetData('/api/WebApi/GetNews')
}


export function useGetNewsTake(take){
	return useGetData('/api/WebApi/GetNews?take='+take)
}
