// ApiContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useInformation } from '../../apiCalling/useInformatio';
import config from '../../config/index'
import { get } from '../../apiservice/apiService';

export const CompanyInformationApiContext = createContext();

export const CompanyInformationApiProvider = ({ children }) => {
  const [clientInformation, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(config.baseUrl +'/api/WebApi/GetInformation');
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <CompanyInformationApiContext.Provider value={{ clientInformation, loading, error }}>
      {children}
    </CompanyInformationApiContext.Provider>
  );
};
