import { useState, useEffect } from 'react';
import { get } from '../apiservice/apiService';


export function useGetData(path){
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
  
	useEffect(() => {
		if (!get) {
		  setError('Internal error');
		  setLoading(false);
		  return;
		}
	  const subscription= get(path).subscribe({
		next: (result) => {
		  setData(result);
		  setLoading(false);
		},
		error: (err) => {
		  setError(err.message);
		  setLoading(false);
		},
	  });
  
	  // Cleanup subscription on unmount
	  return () => {
		subscription.unsubscribe();
	  };
	}, [path]);
  
	return { data, loading, error };
}
