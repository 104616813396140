import React from 'react';
import SectionHeadingStyle2 from '../../SectionHeading/SectionHeadingStyle2';
import GalleryDetails from '../../GalleryDetails';
import config from '../../../config';
import GalleryVideosStyle2 from '../../GalleryDetails/GalleryVideosStyle2';

export default function GalleryDetailsSection({ data, sectionTitle, sectionTitleUp }) {
  const groupedItems = data.galleryDetailsVM?.reduce((acc, item) => {
    const group = item.isVideo ? 'videos' : 'images';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(item);
    return acc;
  }, {});

  return (
    <div className="container">
      {sectionTitle && (
        <SectionHeadingStyle2
          sectionTitle={sectionTitle}
          sectionTitleUp={sectionTitleUp}
          redirectUrl={''}
        />
      )}

      {groupedItems && (
        <>
          {/* Section for Videos */}
          {groupedItems.videos && (
            <>
              <h2>Videos</h2>
              <div className="row justify-content-md-center">
                {groupedItems.videos.map((item, index) => (
                  <div className="col-lg-6" key={index}>
                    <GalleryVideosStyle2
                      imgLink={item.imgLink}
                      isVideo={item.isVideo}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          {/* Section for Images */}
          {groupedItems.images && (
            <>
              <h2>Images</h2>
              <div className="row justify-content-md-center">
                {groupedItems.images.map((item, index) => (
                  <div className="col-lg-4" key={index}>
                    <GalleryDetails
                      imgLink={config.baseUrl + item.imgLink}
                      isVideo={item.isVideo}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );

}
