import React from 'react';

export default function TradeFairDetails({  imgLink }) {
  return (
    <div className="cs_team cs_style_1 text-center cs_mb_25 overflow-hidden cs_rounded_50">
      <div className="cs_team_member position-relative cs_rounded_50">
        <img className="w-100 cs_rounded_50" src={imgLink} alt="Member" />
      </div>
    </div>
  );
}
