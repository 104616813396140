import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import config from "../../config";

export default function ConcernContentCol2({ data }) {
	return (
		<Section pb="80">
			<div className="container">
				<div className="row justify-content-md-center">
					{data?.map((item, index) => (
						<React.Fragment key={index}>
							<div className="col-md-6">
								<img src={config.baseUrl + item.imgLink} className="image-full-weight" />
								<h3>{item.title}</h3>
								<p>{item.description}</p>
							</div>
						</React.Fragment>
					))}
				</div>
			</div>
		</Section>
	);
}
