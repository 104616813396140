import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import { useVideos } from '../../apiCalling/useVideos';
import VideosSection2 from '../Section/PostSection/VideosSection2';
import { useInformation } from '../../apiCalling/useInformatio';
import config from '../../config';



export default function VideosPage() {
  const {data} = useVideos()
  pageTitle('Videos');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Videos" bgUrl={BreadcrumbImage} />

      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <VideosSection2
          data={data}
          sectionTitleUp="Find The Videos"
          sectionTitle="Find Out Latest Videos <br/> and Blogs"
        />
      </Section>
    </>
  );
}
