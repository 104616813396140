import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section";

export default function Crest({ imgLink, name, description }) {
	return (
		<Section ptLg="80" pbLg="55">
			<div className="container">
				<div className="row cs_mb_30">
					<div className="col-xl-4">
						<img src={imgLink} alt="" className="cs_rounded_15 cs_mb_30" />
					</div>
					<div className="col-xl-8">
						<h3 className="cs_fs_26 cs_mb_15">{name}</h3>
						<p className="cs_mb_40 text-justify">{description}</p>
					</div>
				</div>
			</div>
		</Section>
	);
}
