import React, { useEffect, useState } from 'react';
import { useParams} from "react-router-dom";

import Section from '../../Section';

import Pagenation from '../../Pagenation';
import Breadcrumb from '../../Breadcrumb';
import Product from '../../ShopComponents/Product';
import Sort from '../../ShopComponents/Sort';
import Filter from '../../ShopComponents/Filter';
import { pageTitle } from '../../../helpers/PageTitle';
// import { useSearchParams} from "react-router-dom";
import axios from "axios";
import config from '../../../config/index'
import { useInformation } from '../../../apiCalling/useInformatio';

const productData = [
  {
    imgUrl: '/images/shop/product_1.jpeg',
    title: 'Exclusive red car wheel',
    price: '550',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_2.jpeg',
    title: 'Future AI robot toys',
    price: '520',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_3.jpeg',
    title: 'Future AI robot toys',
    price: '20',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_4.jpeg',
    title: 'Future AI robot toys',
    price: '20',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_5.jpeg',
    title: 'Future AI robot toys',
    price: '20',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_6.jpeg',
    title: 'Future AI robot toys',
    price: '20',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_1.jpeg',
    title: 'Exclusive red car wheel',
    price: '550',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_2.jpeg',
    title: 'Future AI robot toys',
    price: '520',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
  {
    imgUrl: '/images/shop/product_3.jpeg',
    title: 'Future AI robot toys',
    price: '20',
    cartList: '/shop-cart',
    shop: '/shop',
    productDetails: '/shop/product-details',
  },
];

export default function OurStorePage() {
  const {catagoryID} = useParams();
  const [catagoryData, setCatagoryData] = useState([])
  pageTitle('Our Product');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.get(`${config.baseUrl}api/WebApi/GetCategoryByCategoryId?id=${catagoryID}`).then((response) => {
      setCatagoryData(response.data);
    });
  }, [catagoryID])
  console.log("catagoryData--", catagoryData)

	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Our Product" lastTitle={catagoryData.name} bgUrl={BreadcrumbImage} />
      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <div className="container">
          <div className="row cs_gap_y_40">
            {/* <div className="col-lg-3">
              <Filter />
            </div> */}
            <div className="col-lg-12">
              {/* <Sort /> */}
              <div className="row cs_gap_y_40">
                {catagoryData.products?.map((item, index) => (
                  <div className="col-lg-4 col-sm-6" key={index}>
                    <Product {...item} />
                  </div>
                ))}
              </div>
              {/* <Pagenation /> */}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
