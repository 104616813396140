import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import { useGetCareer } from "../../apiCalling/useGetCareer";
import { useInformation } from "../../apiCalling/useInformatio";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetDepartment } from "../../apiCalling/useGetDepartment";
import config from "../../config";

export default function CareerPage() {
	const { data } = useGetCareer();
	const { data: department } = useGetDepartment();
	pageTitle("Career");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	const [formData, setFormData] = useState({
		name: '',
		address: '',
		email: '',
		mobile: '',
		mobileAlt: '',
		departmentId: '',
		file: null,
	  });
	
	  const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value
		});
	  };
	
	  const handleFileChange = (e) => {
		setFormData({
		  ...formData,
		  file: e.target.files[0]
		});
	  };
	
	  const handleSubmit = async (e) => {
		e.preventDefault();
	
		const data = new FormData();
		data.append('Name', formData.name);
		data.append('Address', formData.address);
		data.append('Email', formData.email);
		data.append('Mobile', formData.mobile);
		data.append('MobileAlt', formData.mobileAlt);
		data.append('DepartmentId', formData.departmentId);
		data.append('file', formData.file);
	
		try {
		  const response = await fetch(config.baseUrl+'api/WebApi/DropCv', {
			method: 'POST',
			body: data
		  });
	
		  if (response.ok) {
			const result = await response.json();
			if (result.status) {
			  toast.success(result.msg);
			  setFormData({
				name: '',
				address: '',
				email: '',
				mobile: '',
				mobileAlt: '',
				departmentId: '',
				file: null
			  });
			} else {
			  toast.error(result.msg);
			}
		  } else {
			toast.error('Failed to submit CV. Please try again.');
		  }
		} catch (error) {
		  toast.error('An error occurred. Please try again.');
		}
	  };	
	return (
		<>
			<Breadcrumb title="Career" bgUrl={BreadcrumbImage} />

			<Section
				pt="50"
				ptLg="50"
				pb="50"
				pbLg="50"
				className="background-filled  cs_pt_lg_ cs_pb_0 cs_pb_lg_0 bg-gray"
			>
				<div className="container">
					{/* <p className="preline">{info?.career}</p> */}
					<h2 className="text-center">We value your Talent, Skills, and Individuality</h2>
					<p className="text-center">
						Experience the perfect work-life balance where flexibility enchances organizational
						performance, personal benefit, loyalty and dedication to team and individual objectives.
					</p>
					<div className="row justify-content-md-center">
						{data?.map((item, index) => (
							<React.Fragment key={index}>
								<div className="col-md-6 col-lg-4">
									<div className="text-center bg-white career-desc">
										<h3>{item.title}</h3>
										<p>We have a few open positions for you.</p>
										<Link
											class="cs_btn cs_style_1 cs_fs_16  overflow-hidden cs_fs_16 cs_rounded_25 cs_pl_20 cs_pr_20 cs_pt_7 cs_pb_7"
											to={"/career/" + item.id}
										>
											More Details
										</Link>
									</div>
								</div>
							</React.Fragment>
						))}
					</div>
				</div>
			</Section>
			<Section
				pb="50"
				pbLg="50"
				className="background-filled cs_pt_lg_ cs_pb_0 cs_pb_lg_0"
			>
				<form onSubmit={handleSubmit} className="row" encType="multipart/form-data">
					<div className="col-lg-3 col-sm-2"></div>
					<div className="col-lg-6 col-sm-8 p-5 bg-f3f4f7">
						<h3 className="text-center">Drop your CV here</h3>
						<div className="form-group">
							<input
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								placeholder="Name:*"
								required
								autoComplete="off"
								type="text"
								id="Name"
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<input
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								placeholder="Address:*"
								required
								autoComplete="off"
								type="text"
								id="Address"
								name="address"
								value={formData.address}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<input
								type="email"
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								placeholder="Email Address:*"
								required
								autoComplete="off"
								id="Email"
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<input
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								placeholder="Mobile:*"
								required
								autoComplete="off"
								type="text"
								id="Mobile"
								name="mobile"
								value={formData.mobile}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<input
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								placeholder="Mobile Alternative:*"
								required
								autoComplete="off"
								type="text"
								id="MobileAlt"
								name="mobileAlt"
								value={formData.mobileAlt}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label className="control-label" htmlFor="DepartmentId">
								Department
							</label>
							<select
								id="DepartmentId"
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								style={{ width: "100%" }}
								required
								name="departmentId"
								value={formData.departmentId}
								onChange={handleChange}
							>
								<option value="">Select</option>
								{department?.map((item, index) => (
									<React.Fragment key={index}>
										<option value={item.id}>{item.name}</option>
									</React.Fragment>
								))}
							</select>
						</div>
						<div className="form-group">
							<label>Upload your CV (File size less than 1MB)</label>
							<input
								type="file"
								name="file"
								className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_20 bg-gray"
								accept=".pdf,.doc,.docx"
								placeholder="Attached a File:*"
								required
								onChange={handleFileChange}
							/>
						</div>
						<div className="form-group text-center">
							<button
								type="submit"
								className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden"
								style={{ fontFamily: "Poppins, Bangla427, sans-serif" }}
							>
								SUBMIT
							</button>
						</div>
					</div>
				</form>
			</Section>
		</>
	);
}
