import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import SectionHeadingStyle2 from '../SectionHeading/SectionHeadingStyle2';
import Accordion from '../Accordion';
import IconboxStyle5 from '../Iconbox/IconboxStyle5';
import { NavLink, useParams } from 'react-router-dom';
import FormStyle5 from '../Form/FormStyle5';
import { Icon } from '@iconify/react';
import FormStyle6 from '../Form/FormStyle6';
import Button from '../Button';
import { pageTitle } from '../../helpers/PageTitle';
import TeamSection from '../Section/TeamSection';
import axios from 'axios';
import config from '../../config';
import GalleryDetailsSection from '../Section/GalleryDetailsSection';
import { useInformation } from '../../apiCalling/useInformatio';

const serviceList = [
  { title: 'Accounting and Bookkeeping', href: '/service/services-details' },
  {
    title: 'Marketing and Advertising',
    href: '/service/marketing-and-advertising',
  },
  {
    title: 'IT Support and Consulting',
    href: '/service/IT Support and Consulting',
  },
  { title: 'Human Resources', href: '/service/human-resources' },
  { title: 'Web Development', href: '/service/web-development' },
  { title: 'SEO & Content Writing', href: '/service/seo-content-writing' },
];

const faqData = [
  {
    title: 'What services does your business provide?',
    desc: "Our pricing varies depending on the specific services you require and the scope of your project. We offer customized solutions to fit each client's unique needs and budget. Please contact us.",
  },
  {
    title: 'How much do your services cost?',
    desc: "Our pricing varies depending on the specific services you require and the scope of your project. We offer customized solutions to fit each client's unique needs and budget. Please contact us.",
  },
  {
    title: 'Do you offer any guarantees or refunds?',
    desc: "Our pricing varies depending on the specific services you require and the scope of your project. We offer customized solutions to fit each client's unique needs and budget. Please contact us.",
  },
  {
    title: 'How do I get started with your services?',
    desc: "Our pricing varies depending on the specific services you require and the scope of your project. We offer customized solutions to fit each client's unique needs and budget. Please contact us.",
  },
];

export default function GalleryDetailsPage() {
  const { galleryId } = useParams();
	const [galleryData, setGalleryData] = useState([]);
	pageTitle("Gallary Details");

	useEffect(() => {
		axios.get(`${config.baseUrl}api/WebApi/GetGalleryById?Id=${galleryId}`).then((response) => {
			setGalleryData(response.data);
		});
	}, [galleryId]);
	console.log("galleryData--", galleryData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	const { data } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (data?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + data?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Gallary Details" lastTitle={galleryData?.name} bgUrl="/images/page_header_1.jpeg" />

      <Section pt="50" ptLg="50">
        <GalleryDetailsSection
          data={galleryData}
          sectionTitle={galleryData?.name}
          sectionTitleUp={galleryData?.description}
        />
      </Section>

    </>
  );
}
