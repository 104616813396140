import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { pageTitle } from "../../helpers/PageTitle";
import config from "../../config";
import axios from "axios";
import moment from "moment";
import { useInformation } from "../../apiCalling/useInformatio";

export default function NewsDetailsPage() {
	const { newsId } = useParams();
	const [newsData, setNewsData] = useState([]);
	pageTitle("News Details");

	useEffect(() => {
		axios.get(`${config.baseUrl}api/WebApi/GetNewsById?Id=${newsId}`).then((response) => {
			setNewsData(response.data);
		});
	}, [newsId]);
	console.log("newsData--", newsData);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	return (
		<>
			<Breadcrumb title="News Details" lastTitle={newsData.heading} bgUrl={BreadcrumbImage} />

			<Section pt="50" ptLg="50" pb="50" pbLg="50">
				<div className="container">
					<div className="row cs_mb_30">
						<div className="col-xl-4">
							<img
								src={`${config.baseUrl}${newsData.imgLink}`}
								alt=""
								className="cs_rounded_15 cs_mb_30"
							/>
						</div>
						<div className="col-xl-6">
							<h3 className="cs_fs_26 cs_mb_15">
								{newsData.heading}
								<br />
								<span className="cs_fs_12">
									<Icon icon="fa:calendar" /> Published at{" "}
									{moment(newsData.date).format("DD MMM, yyyy, h:mm a")}
								</span>
							</h3>

							<p className="cs_mb_40">{newsData.description}</p>
							{/* <IconboxStyle5
								iconUrl="/images/icons/iconbox_icon_8.svg"
								title="Accounting and Bookkeeping"
							/>
							<IconboxStyle5
								iconUrl="/images/icons/iconbox_icon_9.svg"
								title="IT Support and Consulting"
							/> */}
						</div>
					</div>
				</div>
			</Section>
		</>
	);
}
