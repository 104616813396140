import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import config from "../../config";

export default function ConcernContentCol1({ data }) {
	return (
		<Section pb="80">
			<div className="container">
				{data?.map((item, index) => (
					<React.Fragment key={index}>
						{index % 2 === 0 ? (
							<div className="row pb-20">
								<div className="col-md-6 col-lg-4">
									<img src={config.baseUrl + item.imgLink} className="image-full-weight" />
								</div>
								<div className="col-md-6 col-lg-8">
									<h3>{item.title}</h3>
									<p className="text-justify">{item.description}</p>
								</div>
							</div>
						) : (
							<div className="row pb-20">
								<div className="col-md-6 col-lg-8">
									<h3>{item.title}</h3>
									<p className="text-justify">{item.description}</p>
								</div>
								<div className="col-md-6 col-lg-4">
									<img src={config.baseUrl + item.imgLink} className="image-full-weight" />
								</div>
							</div>
						)}
					</React.Fragment>
				))}
			</div>
		</Section>
	);
}
