import React, { useEffect, useState } from "react";
import Breadcrumb from "../Breadcrumb";
import Section from "../Section";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";
import config from "../../config";
import axios from "axios";
import moment from "moment";
import { useInformation } from "../../apiCalling/useInformatio";

export default function CareerDetailsPage() {
	const { careerId } = useParams();
	const [careerData, setCareerData] = useState([]);
	pageTitle("Career Details");

	useEffect(() => {
		axios.get(`${config.baseUrl}api/WebApi/GetCareerById?Id=${careerId}`).then((response) => {
			setCareerData(response.data);
		});
	}, [careerId]);
	console.log("careerData--", careerData);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	return (
		<>
			<Breadcrumb title={careerData.title} lastTitle={careerData.title} bgUrl={BreadcrumbImage} />
			<Section pt="50" ptLg="50" pb="50" pbLg="50">
				<div className="container">
					<h2 className="text-center">{careerData.title}</h2>
					<div className="row">
						<div className="col-md-8">
							<div className="card">
								<div className="card-header bg-info text-white">Job Description</div>
								<div className="card-body">
									<b>Number of Vacancies:</b>
									<p className="pl-5">
										<span>{careerData.vacancy}</span>
									</p>
									<b>Job Descriptions</b>
									<p className="preline pl-5">
									{careerData.jobDescription}
									</p>
									<b>Qualification</b>
									<p className="preline pl-5">
									{careerData.educationalQualification}
									</p>
									<b>Job Facilities</b>
									<p className="preline pl-5">{careerData.jobFacility}</p>
									<b>Salary</b>
									<p className="pl-5">{careerData.salaryRange}</p>
									<b>Job Location</b>
									<p className="pl-5">{careerData.jobLocation}</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card">
								<div className="card-header bg-success text-white">Job Summary</div>
								<div className="card-body">
									<b>Published on:</b> 	{moment(careerData.createdAt).format("DD MMM, yyyy")}
									<br />
									<b>Vacancy:</b> <span>1</span>
									<br />
									<b>Job Location:</b> {careerData.jobLocation}
									<br />
									<b>Application Deadline:</b> {careerData.applicationDeadline}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Section>
		</>
	);
}
