import React from 'react';
import SectionHeadingStyle2 from '../../SectionHeading/SectionHeadingStyle2';
import GalleryVideosStyle2 from '../../GalleryDetails/GalleryVideosStyle2';

export default function VideosSection2({ data, sectionTitleUp, sectionTitle }) {
  return (
    <div className="container">
      <SectionHeadingStyle2
        sectionTitleUp={sectionTitleUp}
        sectionTitle={sectionTitle}
        redirectUrl={'/videos'}
      />
      <div className="row justify-content-md-center">
        {data.map((item, index) => (
          <div className="col-lg-6" key={index}>
            <GalleryVideosStyle2
              imgLink={item.imgLink}
              isVideo={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
