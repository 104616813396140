import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcrumb";
import Section from "../../Section";
import ImageView from "../../ShopComponents/ImageView";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../../helpers/PageTitle";
import axios from "axios";
import config from "../../../config";
import GalleryVideosStyle2 from "../../GalleryDetails/GalleryVideosStyle2";
import { useInformation } from "../../../apiCalling/useInformatio";

export default function ProductDetailsPage() {
	const { productId } = useParams();
	const [data, setData] = useState([]);
	pageTitle("Product Details");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		axios.get(`${config.baseUrl}api/WebApi/GetProductById?Id=` + productId).then((response) => {
			setData(response.data);
		});
	}, [productId]);
	console.log("productData--", data);
	const groupedItems = data.productImagesVM?.reduce((acc, item) => {
		const group = item.isVideo ? 'videos' : 'images';
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(item);
		return acc;
	}, {});

	console.log("Image Group =======>>>>>>>>>>>> ", groupedItems);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	return (
		<>
			<Breadcrumb title="Product Details" lastTitle={data?.name} bgUrl={BreadcrumbImage} />
			<Section pt="50" ptLg="50" pb="50" pbLg="50">
				<div className="container">
					<div className="cs_pb_20">
						<div className="row">
							{groupedItems && groupedItems.images && (
								<div className="col-lg-5">
									<ImageView data={groupedItems.images} />
								</div>
							)}
							<div className="col-lg-7">
								<div className="cs_single-product-details">
									<h2>{data?.name}</h2>
									<div className="cs_single-product-details-text cs_pb_35">
										<div dangerouslySetInnerHTML={{ __html: data?.description }} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="pt_cs_40">
						{groupedItems && groupedItems.videos && (
							<>
								{/* <h2>Videos</h2> */}
								<div className="row justify-content-md-center">
									{groupedItems.videos.map((item, index) => (
										<div className="col-lg-6" key={index}>
											<GalleryVideosStyle2
												imgLink={item.imgLink}
												isVideo={item.isVideo}
											/>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				</div>
			</Section>
		</>
	);
}
