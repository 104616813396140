import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialBtns({ variant, data }) {
  return (
    <div className={`${variant ? variant : ''}`}>
      <Link
        to={data?.fbLink} target='_blank'
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle cs_social_btns_fb"
      >
        <Icon icon="fa6-brands:facebook-f" />
      </Link>
      <Link
        to={data?.twitterLink} target='_blank'
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle cs_social_btns_x"
      >
        <Icon icon="fa6-brands:twitter" />
      </Link>
      <Link
        to={data?.linkedInLink} target='_blank'
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle cs_social_btns_in"
      >
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link
        to={data?.youtubeLink} target='_blank'
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle cs_social_btns_yt"
      >
        <Icon icon="fa6-brands:youtube" />
      </Link>
      {/* <Link
        to="/"
        className="d-flex align-items-center justify-content-center cs_height_35 cs_width_35 text-white rounded-circle"
      >
        <Icon icon="fa6-brands:dribbble" />
      </Link> */}
    </div>
  );
}
