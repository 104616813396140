import React, { useState } from 'react';
import { toast } from 'react-toastify';
import config from '../../config';

export default function FormStyle2() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(config.baseUrl+'api/WebApi/ContactUs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        toast.success('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          subject: '',
          message: ''
        });
      } else {
        toast.error('Failed to send message. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="col-lg-6">
        <input
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Your Name"
        />
      </div>
      <div className="col-lg-6">
        <input
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Email Address"
        />
      </div>
      <div className="col-lg-6">
        <input
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="mobile"
        />
      </div>
      <div className="col-lg-6">
        <input
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_35 bg-gray"
          type="text"
          placeholder="Subject"
        />
      </div>
      <div className="col-lg-12">
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="form-control cs_fs_14 cs_rounded_5 border-0 cs_mb_40 bg-gray"
          placeholder="Message here ..."
          cols={35}
          rows={7}
        />
      </div>
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden" type="submit">
          <span>Send A Message</span>
        </button>
      </div>
    </form>
  );
}