import { useEffect } from "react";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle.js";
import { useConcernMenu } from "../../apiCalling/useGetConcern.js";
import Breadcrumb from "../Breadcrumb/index.jsx";
import PostSectionStyle2 from "../Section/PostSection/PostSectionStyle2.jsx";
import PostStyle2 from "../Post/PostStyle2.jsx";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import config from "../../config/index.js";
import { useInformation } from "../../apiCalling/useInformatio.js";

export default function ConcernPage() {
	pageTitle("Corporate Home");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data } = useConcernMenu();
	const { data: information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
	console.log("useConcernMenu--", data);

	return (
		<>
			<Breadcrumb title="Concern details" bgUrl={BreadcrumbImage} />
			<Section pt="133" ptLg="70" pb="75" pbLg="45" className="bg-gray">
				<div className="container">
					<div className="container-fluid">
						<div className="row justify-content-md-center">
							{data?.map((item, index) => (
								<div className="col-xl-4" key={index}>
									<div className="cs_post cs_style_1 cs_type_1 bg-white shadow-sm cs_mb_25">
										<Link
											to={'/concern/' + item.url}
											className="cs_post_thumb d-block position-relative overflow-hidden"
										>
											<div
												className="cs_post_thumb-in cs_transition_5 background-filled h-100 w-100"
												style={{ backgroundImage: `url('${config.baseUrl + item.logo}')` }}
											/>
										</Link>
										<div className="cs_post_in cs_pb_30 cs_pr_20  pt-10">
											<Link
												to={'/concern/' + item.url}
												className="cs_post_btn d-flex justify-content-between align-items-center cs_pl_40 cs_pr_40 cs_pb_10 cs_pt_10"
											>
												<span className="cs_post_btn-text">Know More</span>
												<div className="cs_post_btn-icon d-flex cs_transition_4">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M11.9999 0.224976C6.52011 0.224976 1.63131 4.16368 0.485006 9.52707C-0.0876941 12.207 0.298106 15.0567 1.57581 17.4816C2.80551 19.8153 4.82151 21.7014 7.23351 22.7703C9.74241 23.8824 12.6227 24.0762 15.2597 23.3178C17.8037 22.5864 20.0594 20.9811 21.5951 18.8262C24.806 14.3211 24.3767 7.99288 20.5991 3.95608C18.3851 1.59028 15.2405 0.224976 11.9999 0.224976ZM17.6486 12.6291L14.4386 15.9165C13.6259 16.749 12.3413 15.4878 13.1507 14.6592L14.7704 13.0005H7.09461C6.54951 13.0005 6.09471 12.5454 6.09471 12.0006C6.09471 11.4558 6.54981 11.0007 7.09461 11.0007H14.732L13.0802 9.34918C12.2594 8.52838 13.532 7.25548 14.3528 8.07628L17.6411 11.3643C17.9897 11.7126 17.993 12.2766 17.6486 12.6291Z"
															fill="currentColor"
														/>
													</svg>
												</div>
											</Link>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</Section>
		</>
	);
}
