import { useEffect } from 'react';
import HeroStyle2 from '../Hero/HeroStyle2';
import LocationInMap from '../LocationInMap';
import Section from '../Section';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2.jsx';
import BrandSection from '../Section/BrandSection';
import ContactSectionStyle2 from '../Section/ContactSection/ContactSectionStyle2.jsx';
import FaqSection from '../Section/FaqSection/index.jsx';
import PortfolioSectionStyle2 from '../Section/PortfolioSection/PortfolioSectionStyle2.jsx';
import PostSectionStyle2 from '../Section/PostSection/PostSectionStyle2.jsx';
import ServiceSection from '../Section/ServiceSection/index.jsx';
import WorkingProcessSection from '../Section/WorkingProcessSection.jsx';
import TestimonialSliderStyle3 from '../Slider/TestimonialSliderStyle3.jsx';
import VideoBlockStyle2 from '../VideoBlock/VideoBlockStyle2.jsx';
import { pageTitle } from '../../helpers/PageTitle.js';
import Breadcrumb from '../Breadcrumb/index.jsx';
import { useCertificates } from '../../apiCalling/useCertificates.js';
import CertificateSection from '../Section/CertificateSection/index.jsx';
import { useInformation } from '../../apiCalling/useInformatio.js';
import config from '../../config/index.js';

const locationData =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd';

export default function CertificatesPage() {
  pageTitle('Corporate Home');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {data} = useCertificates()
  console.log("useConcernMenu--", data)

	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;

  return (
    <>
      <Breadcrumb title="Certificates" bgUrl={BreadcrumbImage} />
      
      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <div className="container">
          <CertificateSection data={data} />
        </div>
      </Section>
    </>
  );
}
