import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import SectionHeadingStyle2 from '../SectionHeading/SectionHeadingStyle2';
import Accordion from '../Accordion';
import IconboxStyle5 from '../Iconbox/IconboxStyle5';
import { NavLink } from 'react-router-dom';
import FormStyle5 from '../Form/FormStyle5';
import { Icon } from '@iconify/react';
import FormStyle6 from '../Form/FormStyle6';
import Button from '../Button';
import { pageTitle } from '../../helpers/PageTitle';
import { useFaq } from '../../apiCalling/useFaq';
import { useInformation } from '../../apiCalling/useInformatio';
import config from '../../config';

export default function FaqPage() {
  const {data} = useFaq()
  console.log('Faq--->',data)
  pageTitle('Service Details');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Faq" bgUrl={BreadcrumbImage} />
      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="cs_section_heading cs_style_1 cs_mb_30">
                <div className="cs_section_heading_in">
                  <h3 className="cs_fs_20 text-accent fw-normal cs_lh_base cs_mb_15">
                    frequently asked questions
                  </h3>
                  <h2 className="cs_fs_36 cs_mb_0">Questions & Answers</h2>
                </div>
              </div>
              <Accordion data={data} />
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
