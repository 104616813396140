import { useEffect } from "react";
import { pageTitle } from "../../helpers/PageTitle";
import Section from "../Section";
import AboutUsSection from "../Section/AboutSection";
import BrandSection from "../Section/BrandSection";
import ContactSection from "../Section/ContactSection";
import HeroSection from "../Section/HeroSection";
import MovingTextSection from "../Section/MovingTextSection";
import PortfolioSection from "../Section/PortfolioSection";
import PostSection from "../Section/PostSection";
import ServiceSection from "../Section/ServiceSection";
import TeamSection from "../Section/TeamSection";
import TestimonialSection from "../Section/TestimonialSection";
import WhyChooseUsSection from "../Section/WhyChooseUsSection";
import { useGetSlider } from "../../apiCalling/useGetSlider";
import { useGetBrand } from "../../apiCalling/useGetBrand";
import { useGetNewsTake } from "../../apiCalling/useGetNews";
import { BasicInformation, ContactInformation } from "../../constatnts/information";
import { useInformation } from "../../apiCalling/useInformatio";
import BrandScrollSection from "../Section/BrandScrollSection";
import { useVideos } from "../../apiCalling/useVideos";
import VideosSection from "../Section/PostSection/VideosSection";

export default function Home() {
	pageTitle(BasicInformation.companyName + " | Home");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data } = useGetSlider();
	const { data: brand } = useGetBrand();
	const { data: news } = useGetNewsTake(3);
	const { data: information } = useInformation();
	const { data: videos } = useVideos(3);
	console.log("information--->", information);
	return (
		<>
			<HeroSection data={data} info={information} />

			{/* <Section>
        <MovingTextSection data={movingTextData} />
      </Section> */}

			<Section
				pt="50"
				ptLg="50"
				pb="50"
				pbLg="50"
				className="cs_gray_bg"
				// style={{ backgroundImage: 'url("/images/services_bg.png")' }}
			>
				<div
					className="container"
					// style={{
					//   paddingLeft: '40px',
					//   paddingRight: '40px'
					// }}
				>
					<h3 className=" text-center">Summary</h3>
					<p className="text-justify"> {information?.companySummary} </p>
				</div>
				{/* <ServiceSection
          sectionTitleUp="Our Service List"
          sectionTitle="We Provide The Solution <br />For Our Clients"
          sectionSubTitle="I have been a loyal customer of this auto parts company for years and I cannot recommend them enough. Their extensive selection of high-quality parts and accessories."
          sectionTitleDown="Digital agency  services built specifically for your business"
          sectionBtnText="Find More Services"
          sectionBtnUrl="/service"
          data={serviceData}
        /> */}
			</Section>

			{/* <Section
        pt="135"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="position-relative"
      >
        <AboutUsSection
          thumbUrl="/images/experience_img.jpeg"
          experienceBgUrl="/images/experience_bg.jpeg"
          experienceIconUrl="/images/experience_icon.svg"
          experienceNumber="40"
          experienceTitle="Work Experience"
          sectionTitleUp="About Us"
          sectionTitle='Get Ready for Success with <span className="text-accent">Partex</span> Your Path to Growth.'
          sectionSubTitle="Providing legal advice, contract drafting, compliance assistance, intellectual property protection, and other legal support for businesses. Creating visual content, such as logos, brochures, infographics, and other branding materials."
          grothTitle="Web development"
          grothPercentage="90"
          featureList={[
            {
              title: 'Accounting and Bookkeeping',
              subTitle:
                'Services related to financial record-keeping, bookkeeping.',
            },
            {
              title: 'Human Resources (HR) Consulting',
              subTitle: 'Assistance with HR-related tasks such as recruitment.',
            },
          ]}
          btnText="Get A Quote"
          btnUrl="/contact"
          videoBtnText="Watch the video"
          videoBtnUrl="https://www.youtube.com/embed/0Tz4Ycjbdbg"
        />
      </Section> */}

			<Section pt="80" ptLg="" className="">
				<BrandScrollSection
					sectionTitle="Take a look our Brand"
					sectionTitleUp="Our Brand"
					data={brand}
				/>
			</Section>

			{/* <Section
        pt="135"
        ptLg="75"
        pb="100"
        pbLg="40"
        className="position-relative cs_iconbox_2_wrap overflow-hidden"
      >
        <WhyChooseUsSection
          imgUrlLeft="/images/why_choose_us_left_img.jpeg"
          imgUrlRight="/images/why_choose_us_right_img.jpeg"
          sectionTitleUp="Why Choose Us"
          sectionTitle='Developing a <span className="text-accent"> design that <br /> is easy </span> to use and <br /> navigate'
          sectionSubTitle="Providing legal advice, contract drafting, compliance
          assistance, intellectual <br /> property protection, and other
          legal support for businesses. Creating visual <br /> content,
          such as logos, brochures, infographics."
          services={[
            {
              imgUrl: '/images/icons/service_icon_5.svg',
              title: 'Digital agency services',
            },
            {
              imgUrl: '/images/icons/service_icon_6.svg',
              title: 'Creative digital agency',
            },
            {
              imgUrl: '/images/icons/service_icon_7.svg',
              title: 'Super expert developers',
            },
            {
              imgUrl: '/images/icons/service_icon_8.svg',
              title: 'Creating a user friendly design',
            },
          ]}
        />
      </Section> */}

			{/* <Section pt="140" ptLg="80">
        <BrandSection data={brand} />
      </Section> */}

			<Section pt="80" ptLg="75" pb="80" pbLg="50">
				<PostSection
					data={news}
					sectionTitleUp="Find The News"
					sectionTitle="Find Out Latest News <br/> and Articles"
				/>
			</Section>

      
			<Section pt="80" ptLg="75" pb="80" pbLg="50">
				<VideosSection
					data={videos}
					sectionTitleUp="Find The Videos"
					sectionTitle="Find Out Latest Videos <br/> and blogs"
				/>
			</Section>
		</>
	);
}
